import ReportForm from '@/components/Report/ReportForm/ReportForm.vue'

export default {
  components: {
    ReportForm
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Reports',
          disabled: false,
          to: { name: 'list-reports' },
          exact: true
        },
        { text: 'Create' }
      ]
    }
  }
}
